


















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import vesselOverviewRoutes from "@/router/vesselOverview";

@Component({ components: {} })
export default class VesselOverview extends Vue {
  private routes = vesselOverviewRoutes;
}
